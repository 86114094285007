import React from "react";
import { Accordion, AccordionItem } from "react-sanfona";

export default function Questions() {
  return (
    <div>
      <Accordion>
        <AccordionItem title={"What is the intent of this policy?"}>
          <div>
            The intent of Petcare.solutions’ privacy policy is to inform you
            about how our company collects, uses, stores, and discloses your
            personal information, your options and rights, and our other
            conditions in relation to this policy. Our policy contains honest,
            direct language that includes internal links so it’s easier to
            navigate through the text. Please contact us if you would like to
            provide feedback or questions about this policy.
          </div>
        </AccordionItem>
        <AccordionItem title={"Who is Petcare.solutions?"}>
          <div>
            Petcare.solutions provides you with the answers you need for you and
            your pet. Our company assists you with any pet-related issues that
            require non-life threatening help by keeping detailed, up-to-date
            records, diagnoses, warranty questions, and more. We provide a
            multi-faceted approach to our work to help you maintain a lifelong
            friendship with your pet.
          </div>
        </AccordionItem>
        <AccordionItem title={"Who is covered by this policy?"}>
          <div>
            Petcare.solutions services directly to business, ongoing customers,
            and prospective customers in the United States. All of our
            materials, including website, products, and services, are intended
            for use by United States residents, and are bound by the laws of the
            United States and the State of Florida. We collect personal and
            non-personal information from our ongoing and prospective adult
            customers. Should we accidentally collect personal data from a minor
            (someone younger than 18 years of age), we make sure to delete this
            information as soon as possible. If you believe that a non-U.S.
            resident or a minor has provided sensitive information to us, please
            contact us immediately.
          </div>
        </AccordionItem>
        <AccordionItem title={"What is the scope of the policy?"}>
          <div>
            This privacy policy details how Petcare.solutions’ handles your
            personal and non-personal data. We explore the different types of
            information gathered by our company under the name Petcare.solutions
            in the following sections.
            <br></br>
            <br></br>
            This policy is intended to be extensive and informative, and fully
            presents how we treat you personal and non-personal information
            through:
            <br></br>
            <ul>
              <li>Our website</li>
              <li>Email</li>
              <li>Telephone calls</li>
              <li>In-person communication</li>
              <li>Mail</li>
              <li>Third-party sources</li>
              <li>Any other sources involving our company</li>
            </ul>
          </div>
        </AccordionItem>
        <AccordionItem
          title={
            "How do Petcare.solutions’ websites respond to Do Not Track signals?"
          }
        >
          <div>
            Many browsers allow users to send “Do Not Track” signals to websites
            that prevents them from using tracking mechanisms for the requesting
            users. We honor “Do Not Track” signals, and so, we will not track,
            plant cookies, or use advertising when a “Do Not Track” signal is in
            place. We use a trusted third-party plugin to achieve this, though
            we must note that no plugin is 100% foolproof. We make no guarantee
            over the effectiveness of the plugin we use.
          </div>
        </AccordionItem>
        <AccordionItem
          title={
            "What personal and other information does Petcare.solutions gather?"
          }
        >
          <div>
            We collect different categories of information to help provide our
            site to you and other users, improve user experience, and
            communicate with you and other users about our services.The
            information we collection from you includes broad, non-specific
            information that isn’t directly tied to a specific person, and
            “personally identifiable information” that includes your first and
            last name, email address, or telephone number.
            <br></br>
            <br></br>
            When reporting a pet-related issue to our team via website or phone
            call, you may be asked to share your name, email address, phone
            number or other important details to improve your overall
            experience.
          </div>
        </AccordionItem>
        <AccordionItem
          title={"How does Petcare.solutions protect my information?"}
        >
          <div>
            Petcare.solutions take data security seriously. We have reasonable
            security measures in place on our site that allows us to protect
            your information. Some of these preventative measures include:
            <br></br>
            <ul>
              <li>Only providing articles and information</li>
              <li>Never asking for credit card numbers</li>
              <li>Using regular Malware Scanning</li>
            </ul>
          </div>
        </AccordionItem>
        <AccordionItem
          title={"What options do I have when you communicate with me?"}
        >
          <div>
            If you no longer wish to receive emails from our company, you can
            unsubscribe by clicking the “Unsubscribe” link in the next email you
            receive from us. You can also unsubscribe from receiving telephone
            calls about our products or services by asking us to remove your
            number from our call list.
            <br></br>
            Please note that if you speak with our call center staff, your call
            session may be monitored and recorded for training and quality
            assurance purposes. If you have any questions or concerns about our
            policies, don’t hesitate to contact us.
          </div>
        </AccordionItem>
        <AccordionItem title={"What is your cookies and beacons policy?"}>
          <div>
            We reserve the right to use cookies and beacons when you interact
            with us through our website. Cookies are small files that are stored
            on your browser when you visit a website. These small files
            (sometimes called “single-pixel gif files” or “clear gif files”)
            help us recognize your preferences, visits, prior activities, etc.
            By visiting your browser settings, you can change your preferences
            regarding cookies, and prevent your browser from accepting new
            cookies. You can also disable cookies by changing browser add-ons’
            settings.
            <br></br>
            <br></br>
            Beacons (also called “web beacon”) are graphic images, usually
            around 1 pixel x 1 pixel in size, that are stored on a website or
            email to monitor user behavior and collect other information. These
            images are typically used hand-in-hand with cookies. Beacons are not
            browser-based and do not store data on a computer.
            <br></br>
            <br></br>
            If you would like to learn more about how we use cookies and
            beacons, please contact us.
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
