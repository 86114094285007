import React from 'react';
import Helmet from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from 'components/Container';
import '../assets/stylesheets/styles.scss';
import doggy from '../assets/images/doggy.png';
import leftWave from '../assets/images/left-wave.svg';
import rightSmallWave from '../assets/images/right-small-wave.svg';
import rightBigWave from '../assets/images/right-big-wave.svg';
import Questions from '../components/Questions';

const SecondPage = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <div className="hero">
                <Header />
                <Container>
                    <div className="hero__container">
                        <div className="hero__img">
                            <img className="hero__doggy" src={doggy}></img>
                            <img
                                className="hero__left-wave"
                                src={leftWave}
                            ></img>
                        </div>
                        <div className="hero__content">
                            <h1 className="hero__title">PRIVACY POLICY</h1>
                            <p className="hero__description">
                                Petcare.solutions respects your personal
                                information and works to safeguard it. Please
                                read this privacy policy carefully to understand
                                how we use and protect your data. By using this
                                website, you consent to the collection and use
                                of your data as listed in this privacy policy.
                            </p>
                            <img
                                className="hero__right-small-wave"
                                src={rightSmallWave}
                            ></img>
                            <img
                                className="hero__right-big-wave"
                                src={rightBigWave}
                            ></img>
                        </div>
                    </div>
                </Container>
            </div>

            <div class="privacy-policy-mobile">
                <Container>
                    <h1 className="privacy-policy-mobile__title">
                        PRIVACY POLICY
                    </h1>
                    <p className="privacy-policy-mobile__description">
                        Petcare.solutions respects your personal information and
                        works to safeguard it. Please read this privacy policy
                        carefully to understand how we use and protect your
                        data. By using this website, you consent to the
                        collection and use of your data as listed in this
                        privacy policy.
                    </p>
                </Container>
            </div>

            <Container>
                <section className="policy-card">
                    <Questions />

                    <Container>
                        <section className="information">
                            <span className="information__hours">
                                Our business hours (Eastern Time Zone) are:{' '}
                                <br></br>Monday – Sunday 10:00 AM – 7:00 PM EST.
                            </span>
                            <span className="information__phone">
                                <span className="information__urgency">
                                    URGENCIES PLEASE CALL US
                                </span>
                                1-800-513-8570
                            </span>
                        </section>
                    </Container>
                </section>
            </Container>
            <div className="spacer"></div>
            <Footer />
        </>
    );
};

export default SecondPage;
