import React from 'react';
import logo from '../assets/images/solutions-logo.png';
import Container from 'components/Container';
import { Link } from 'gatsby';
import businessLogo from '../assets/images/bbb-logo.png';

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <div className="footer__content">
                    <div className="mobile-section">
                        <span className="mobile-title">
                            <Link to="/policy">Privacy Policy</Link>
                        </span>
                        <div className="mobile-divider"></div>
                        <div className="mobile-hours">
                            <span>
                                Our business hours (Eastern Time Zone)<br></br>
                                Monday – Sunday <br></br>
                                10:00 AM – 7:00 PM EST.<br></br>
                            </span>
                        </div>
                        <div className="mobile-divider"></div>
                        <span className="urgency">
                            URGENCIES PLEASE CALL US{' '}
                            <span> 1-800-513-8570</span>
                        </span>
                        {/* <img src={businessLogo} className="logo" /> */}
                    </div>
                    <p className="footer__copyright">
                        All Rights Reserved &copy; {new Date().getFullYear()}.
                    </p>
                    <Link to="/">
                        <img className="footer__img" src={logo} />
                    </Link>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
